<script lang="ts">
  import { onMount } from "svelte";
  type SlotChar = "F" | "O" | "N" | "T" | "S";
  type PathTuple = [string, string, string, string, string];
  import { Spring } from "svelte/motion";
  // ORDER:
  // 0. Airdancer
  // 1. Ciao
  // 2. Erika
  // 3. Kosmos
  // 4. Tgr
  const PATHS: Record<SlotChar, PathTuple> = {
    F: [
      "m64.15,285.04c12.32,0,21.44-2.36,27.36-7.08,5.92-4.72,8.88-12.76,8.88-24.12v-22.8h45.12c7.52,0,13.28-2.04,17.28-6.12,4-4.08,6-9.8,6-17.16v-3.12c0-7.36-2-13.04-6-17.04-4-4-9.76-6-17.28-6h-45.12v-17.76h55.68c7.52,0,13.28-2,17.28-6,4-4,6-9.76,6-17.28v-3.84c0-7.52-2-13.28-6-17.28-4-4-9.76-6-17.28-6H40.63c-7.2,0-12.72,1.92-16.56,5.76s-5.76,9.36-5.76,16.56v118.08c0,11.36,2.96,19.4,8.88,24.12,5.92,4.72,15.12,7.08,27.6,7.08h9.36Z",
      "M179.4,120c-16.2-8.8-37.4-21.2-66.7-21.2h-0.5c-40.3,0-73.1,28.6-72.4,65.6c0.2,10.4,7.9,19.8,18.9,20.4 c5.5,8.2,14.6,13.8,25.9,16.8l-0.7,24.5c-7.6,2.7-13,9.7-13.5,18L69.2,266c-0.8,16.2,11.1,28.5,26.2,28.5H96 c11.2,0,22.4-6.8,29.5-14.3l5.5-5.7c3.5-3.6,5.7-8.6,5.7-13.9c0-11-8.9-19.9-19.9-19.9c-2.5,0-4.7,0.5-6.9,1.3 c-0.9-7.1-5.9-13.2-12.8-15.7l0.7-22.5c2,0.1,4,0.1,6,0.1c8,5.5,21.6,12.2,29.9,11.7l26.4-1.4c11-0.6,19.6-10,18.9-21 c-0.5-10.9-10-19.3-20.9-18.8l-26.5,1.4c-8.1,0.4-20.7,8.3-28.1,14.6c-13.8-0.1-24.8-3.3-31.3-10.1c4.3-3.5,7.1-8.8,7.4-14.8 c0.6-17.3,10.3-27,32.6-27h0.5c18.5,0,31.3,7.4,47.5,16.3c2.9,1.5,6.1,2.5,9.6,2.5c11,0,19.9-8.9,19.9-19.9 C189.7,130,185.4,123.4,179.4,120z",
      "M 161.75999450683594 115.3499984741211 L 161.75999450683594 109.58999633789062 L 71.2699966430664 109.58999633789062 L 71.2699966430664 277.6000061035156 L 77.04000091552734 277.6000061035156 L 77.04000091552734 195.75999450683594 L 152.63999938964844 195.75999450683594 L 152.63999938964844 190 L 77.04000091552734 190 L 77.04000091552734 115.3499984741211 L 161.75999450683594 115.3499984741211 Z",
      "m162.36,110.61h-82.56c-4.56,0-11.28,1.2-11.28,10.56v150.24c0,5.04,4.08,8.4,8.16,8.4,4.32,0,8.64-3.36,8.64-8.4v-68.4h61.44c4.8,0,8.64-3.84,8.64-8.64s-3.84-8.64-8.64-8.64h-61.44v-57.84h77.04c4.56,0,8.64-3.84,8.64-8.64s-3.84-8.64-8.64-8.64Z",
      "M 88.08000183105469 204.39999389648438 L 173.27999877929688 204.39999389648438 L 173.27999877929688 175.60000610351562 L 88.08000183105469 175.60000610351562 L 88.08000183105469 135.75999450683594 L 176.8800048828125 135.75999450683594 L 176.8800048828125 106.95999908447266 L 53.279998779296875 106.95999908447266 L 53.279998779296875 274.9599914550781 L 88.08000183105469 274.9599914550781 L 88.08000183105469 204.39999389648438 Z",
    ],
    O: [
      "m162.07,116.44c-12.32-3.92-29.12-5.88-50.4-5.88h-12c-21.12,0-37.68,1.96-49.68,5.88-12,3.92-20.56,10-25.68,18.24-5.12,8.24-7.68,19.24-7.68,33v60.24c0,13.76,2.56,24.76,7.68,33,5.12,8.24,13.68,14.32,25.68,18.24,12,3.92,28.56,5.88,49.68,5.88h12c21.28,0,38.08-1.96,50.4-5.88,12.32-3.92,21.12-10,26.4-18.24,5.28-8.24,7.92-19.24,7.92-33v-60.24c0-13.76-2.64-24.76-7.92-33-5.28-8.24-14.08-14.32-26.4-18.24Zm-49.68,130.68c0,3.04-.56,5.28-1.68,6.72-1.12,1.44-2.56,2.16-4.32,2.16-4,0-6-2.96-6-8.88v-98.64c0-5.92,2-8.88,6-8.88s6,2.96,6,8.88v98.64Z",
      "M115.5,98.8h-1.4c-36.6,0-65.4,27.5-77,59.3c-0.7,2.1-1.2,4.5-1.2,6.8c0,7.1,3.8,13.4,9.3,16.9c-1.1,7.7-1.4,15.8-1.2,23.8 c0.4,8.4,1.5,16.3,3.5,23.6c-4.7,3.6-7.6,9.4-7.6,15.7c0,3.4,0.8,6.6,2.3,9.4c12.5,23.7,37.7,40.4,66.7,40.4h1.4 c30.9,0,56-16.8,69.5-39.7c1.8-2.9,2.7-6.4,2.7-10.1c0-6-2.6-11.2-6.7-14.9c1.3-4.5,2.5-9.1,3.4-14.1c1.6-8.7,2.5-19.2,2.3-28.4 c0-1.9-0.1-3.8-0.2-5.6c5.7-3.4,9.6-9.7,9.6-17c0-2-0.2-3.9-0.8-5.7C180.2,126.1,150.5,98.8,115.5,98.8z M166.1,213.1 c-0.7,4.2-1.6,8.1-2.8,11.8h-0.7c-7.4,0-13.7,4-17.1,9.8c-8.3,14.2-20.5,20-34.5,20h-1.4c-13.1,0-24.7-5.3-32.2-19.3 c-3.3-6.2-9.6-10.3-17-10.5c-1.6-6-2.6-12.5-2.9-19.7c-0.4-7.3-0.1-14.3,0.7-20.5c7.5-0.9,13.8-6.1,16.4-13 c7.9-21.7,20.6-33,38.9-33h1.4c17,0,30.6,10.5,37,32c2.2,7.3,8.4,12.9,16.2,13.9l0.1,3C168.3,195.8,167.5,205.6,166.1,213.1z",
      "m165.24,193.6c0-43.68-15.6-85.68-57.6-85.68s-57.6,42-57.6,85.68,15.6,85.92,57.6,85.92,57.6-42.24,57.6-85.92Zm-109.44,0c0-37.2,12-79.92,51.84-79.92s51.84,42.72,51.84,79.92-12,80.16-51.84,80.16-51.84-42.96-51.84-80.16Z",
      "m109.63,108.21c-47.52,0-76.32,37.68-76.32,86.16s30,86.64,76.32,86.64,76.56-37.44,76.56-86.64-29.28-86.16-76.56-86.16Zm0,155.52c-36,0-59.52-30-59.52-69.36,0-37.2,21.36-68.88,59.52-68.88s59.76,30,59.76,68.88-22.8,69.36-59.76,69.36Z",
      "m154.8,117.4c-13.68-7.76-29.4-11.64-47.16-11.64s-33.48,3.88-47.16,11.64c-13.68,7.76-24.24,18.12-31.68,31.08s-11.16,26.88-11.16,41.76,3.56,28.68,10.68,41.88c7.12,13.2,17.52,23.92,31.2,32.16,13.68,8.24,29.72,12.36,48.12,12.36s34.44-4.12,48.12-12.36c13.68-8.24,24.08-18.96,31.2-32.16,7.12-13.2,10.68-27.16,10.68-41.88s-3.72-28.8-11.16-41.76c-7.44-12.96-18-23.32-31.68-31.08Zm.12,100.08c-4.48,8.72-10.84,15.76-19.08,21.12-8.24,5.36-17.64,8.04-28.2,8.04s-19.96-2.68-28.2-8.04c-8.24-5.36-14.6-12.4-19.08-21.12-4.48-8.72-6.72-17.8-6.72-27.24s2.24-18.56,6.72-26.88c4.48-8.32,10.8-15,18.96-20.04,8.16-5.04,17.6-7.56,28.32-7.56s20.16,2.52,28.32,7.56c8.16,5.04,14.48,11.72,18.96,20.04,4.48,8.32,6.72,17.28,6.72,26.88s-2.24,18.52-6.72,27.24Z",
    ],
    N: [
      "m194.02,119.56c-5.84-4.56-14.92-6.84-27.24-6.84h-9.6c-12.48,0-21.6,2.36-27.36,7.08-5.76,4.72-8.4,12.92-7.92,24.6l1.68,51.36c0,.48-.2.76-.6.84-.4.08-.68-.12-.84-.6l-12.96-52.32c-2.72-11.2-7.72-19.2-15-24-7.28-4.8-16.52-7.2-27.72-7.2h-17.76c-12.32,0-21.44,2.32-27.36,6.96-5.92,4.64-8.88,12.64-8.88,24v110.4c0,11.36,2.92,19.36,8.76,24,5.84,4.64,15,6.96,27.48,6.96h9.84c12.32,0,21.36-2.44,27.12-7.32,5.76-4.88,8.4-13.08,7.92-24.6l-1.92-51.36c0-.48.2-.72.6-.72s.68.16.84.48l14.88,60c1.44,6.56,4.04,11.56,7.8,15,3.76,3.44,8.32,5.76,13.68,6.96,5.36,1.2,11.96,1.8,19.8,1.8h17.52c12.16,0,21.2-2.36,27.12-7.08,5.92-4.72,8.88-12.76,8.88-24.12v-110.4c0-11.36-2.92-19.32-8.76-23.88Z",
      "M182.5,240.6c-2.6,0-5.2,0.5-7.4,1.4c-1.1-7.3-6-13.5-13.1-15.9l0.8-42.2c8-2.5,13.7-9.8,13.8-18.6l0.1-11 c0.2-37.5-11.1-55.4-39.6-55.4h-0.4c-15.8,0-29.9,8.9-42.1,28.7c-1.9,3.2-3,6.8-3,10.7c0,4.9,1.8,9.5,4.7,12.9 c-1.8,3.8-3.6,7.6-5.6,12l-16.6,35.9l1.4-48.6c5.6-3.4,9.4-9.5,10.4-16.5l1.4-8.8c2.5-15.1-8.2-26.1-21.3-26.1h-0.2h-4 c-13.4,0-20.3,8.2-24.6,20.2l-0.5,1.3c-3.6,10-0.7,18.8,7.3,23.2l12.2,6.8c1.9,1.1,3.9,1.8,6,2.2l-2.2,73.7 c-7.4,2.8-12.8,9.7-13,17.9L46.2,266c-0.5,16.2,11.1,28.5,26.2,28.5H73c11.2,0,22.4-6.8,29.5-14.3l5.5-5.7 c3.5-3.6,5.7-8.6,5.7-13.9c0-10.9-8.8-19.9-19.9-19.9c-2.6,0-5.2,0.5-7.4,1.4c-0.9-6.4-5-12.2-10.9-15l27.3-58.5l5-10.8 c1.2,0.2,2.3,0.4,3.6,0.4c7.1,0,13.4-3.8,16.8-9.4c3.5-5.6,6.6-10.1,8.4-10.1h0.4c0.1,0,0.1,1.2-0.2,25.9 c-0.2,8.2,4.9,15.8,12.5,18.9l-0.8,42.8c-7.5,2.7-12.9,9.7-13.1,17.9l-0.7,21.9c-0.5,16.2,11.1,28.5,26.2,28.5h0.6 c11.2,0,22.4-6.8,29.5-14.3l5.5-5.7c3.5-3.6,5.7-8.6,5.7-13.9C202.4,249.6,193.6,240.6,182.5,240.6z",
      "M 64.55999755859375 109.58999633789062 L 57.119998931884766 109.58999633789062 L 57.119998931884766 277.6000061035156 L 62.880001068115234 277.6000061035156 L 62.880001068115234 118.70999908447266 L 150 277.6000061035156 L 158.16000366210938 277.6000061035156 L 158.16000366210938 109.58999633789062 L 152.39999389648438 109.58999633789062 L 152.39999389648438 269.9200134277344 L 64.55999755859375 109.58999633789062 Z",
      "m162.18,109.41c-5.52,0-8.16,4.56-8.16,8.16l-.48,127.2L62.33,113.01c-1.68-2.4-3.84-3.6-6.96-3.6-5.76,0-8.4,4.56-8.4,8.16v154.32c0,4.08,3.36,7.92,8.4,7.92s8.4-4.32,8.4-7.92v-126.72l90.96,131.28c1.68,2.16,4.08,3.36,6.96,3.36,4.32,0,8.64-3.36,8.64-7.92l.48-154.32c0-4.32-3.84-8.16-8.64-8.16Z",
      "M 68.27999877929688 154 L 68.76000213623047 154 L 139.07000732421875 274.9599914550781 L 181.8000030517578 274.9599914550781 L 181.8000030517578 106.95999908447266 L 146.99000549316406 106.95999908447266 L 146.99000549316406 228.16000366210938 L 146.50999450683594 228.16000366210938 L 75.72000122070312 106.95999908447266 L 33.470001220703125 106.95999908447266 L 33.470001220703125 274.9599914550781 L 68.27999877929688 274.9599914550781 L 68.27999877929688 154 Z",
    ],
    T: [
      "m57.68,161.68v92.16c0,11.52,2.96,19.64,8.88,24.36,5.92,4.72,15.12,7.08,27.6,7.08h10.8c12.48,0,21.72-2.36,27.72-7.08,6-4.72,9-12.84,9-24.36v-92.16h21.84c7.68,0,13.56-2.04,17.64-6.12s6.12-9.88,6.12-17.4v-1.2c0-7.52-2.04-13.32-6.12-17.4s-9.96-6.12-17.64-6.12H35.59c-7.68,0-13.56,2.04-17.64,6.12s-6.12,9.88-6.12,17.4v1.2c0,7.52,2.04,13.32,6.12,17.4s9.96,6.12,17.64,6.12h22.08Z",
      "M182.4,118.5c-19.6-9.6-45.5-19.2-67.7-19.2c-21.4,0-43.6,8.7-66.8,17.9c-7.4,2.9-12.5,10.1-12.5,18.5 c0,11,8.9,19.9,19.9,19.9c2.6,0,5-0.5,7.4-1.4c19-7.6,33-13,44.9-14.6l-2.2,87.1c-7.1,2.8-12.2,9.6-12.7,17.7l-1.2,21.9 c-0.8,16.2,11.1,28.5,26.2,28.5h0.6c11.2,0,22.6-6.4,29.5-14.3l11-12.4c3.4-3.8,5.7-8.6,5.7-13.9c0-11-8.9-19.9-19.9-19.9 c-5,0-9.7,1.6-13.1,4.8c-1.9-6-6.6-10.8-12.8-12.8l2.1-86.7c13.1,1.5,28.8,7.4,44.2,14.9c2.6,1.3,5.5,2,8.7,2 c11,0,19.9-8.9,19.9-19.9C193.7,128.5,189.1,121.7,182.4,118.5z",
      "M 114 277.6000061035156 L 114 115.3499984741211 L 162.9600067138672 115.3499984741211 L 162.9600067138672 109.58999633789062 L 58.79999923706055 109.58999633789062 L 58.79999923706055 115.3499984741211 L 108.23999786376953 115.3499984741211 L 108.23999786376953 277.6000061035156 L 114 277.6000061035156 Z",
      "m165.12,110.79H57.12c-4.56,0-8.4,3.6-8.4,8.64s3.84,8.64,8.4,8.64h45.36v143.76c0,4.56,3.84,8.16,8.4,8.16s8.4-3.6,8.4-8.16v-143.76h45.84c4.08,0,8.16-3.6,8.16-8.64s-3.6-8.64-8.16-8.64Z",
      "M 93.11000061035156 274.9599914550781 L 127.91000366210938 274.9599914550781 L 127.91000366210938 135.75999450683594 L 186.9499969482422 135.75999450683594 L 186.9499969482422 106.95999908447266 L 34.06999969482422 106.95999908447266 L 34.06999969482422 135.75999450683594 L 93.11000061035156 135.75999450683594 L 93.11000061035156 274.9599914550781 Z",
    ],
    S: [
      "m125.72,172.12c6.48,3.28,15.96,4.92,28.44,4.92h5.76c12.16,0,21.28-1.6,27.36-4.8,6.08-3.2,9.12-8.48,9.12-15.84v-6.24c0-8-2.6-14.96-7.8-20.88-5.2-5.92-13.56-10.52-25.08-13.8-11.52-3.28-26.48-4.92-44.88-4.92h-24.24c-18.72,0-33.84,1.68-45.36,5.04-11.52,3.36-19.8,8.08-24.84,14.16-5.04,6.08-7.56,13.52-7.56,22.32v5.04c0,7.68,2.4,14.76,7.2,21.24s12.16,11.96,22.08,16.44l51.6,22.08c3.68,1.76,6.4,4.04,8.16,6.84,1.76,2.8,2.8,5.88,3.12,9.24.16,1.12.24,2.4.24,3.84v10.32c0,5.92-2,8.88-6,8.88s-6-2.96-6-8.88v-7.92c0-9.76-3.2-16.56-9.6-20.4-6.4-3.84-15.92-5.76-28.56-5.76h-5.76c-12.64,0-21.88,1.92-27.72,5.76-5.84,3.84-8.76,10.64-8.76,20.4v6.24c0,8.16,2.56,15.12,7.68,20.88,5.12,5.76,13.48,10.32,25.08,13.68,11.6,3.36,26.6,5.04,45,5.04h23.28c19.2,0,34.56-1.68,46.08-5.04s19.84-8.24,24.96-14.64c5.12-6.4,7.68-14.24,7.68-23.52v-8.4c0-7.68-2.32-14.48-6.96-20.4-4.64-5.92-12-11.28-22.08-16.08l-54-24.24c-3.2-1.6-5.52-3.8-6.96-6.6-1.44-2.8-2.24-5.64-2.4-8.52v-8.64c0-6.24,2-9.36,6-9.36s6,3.12,6,9.36v7.44c0,7.2,3.24,12.44,9.72,15.72Z",
      "M109,138.8h0.1c14.1,0,24,5.6,40.1,14.6c2.8,1.6,6.2,2.6,9.7,2.6c11,0,19.9-8.9,19.9-19.9c0-7.5-4.1-13.9-10.2-17.3 C149.5,108,131.2,99,109.2,99H109c-32.1,0-61.9,21.7-62.3,59.4c-0.1,10.8,8.4,20.3,19.9,20.3l1.9-0.1c6,6.2,14.5,10.5,23,15.6 l34,20c7.1,4.2,13.2,7.6,17.5,11.2c-4.6,3.5-7.5,9-7.7,15.2c-0.2,9.6-9.3,14.3-19.5,14.3h-0.2c-14.9,0-22.9-5-40.4-20.3 c-3.5-2.9-8.1-4.8-13-4.8c-11,0-19.9,8.9-19.9,19.9c0,6,2.7,11.5,6.9,15.1c20.6,17.7,39.3,29.9,66.4,29.9h0.2 c29.9,0,58.5-24.3,59.3-53c0.4-10-7.1-19.1-17.5-20.4c-6.1-7.9-16.2-13.4-26.6-19.6l-34-20c-6.3-3.8-11.7-6.7-15.7-9.6 c3.3-3.5,5.3-8.1,5.3-13.1C86.8,145.6,96.2,138.8,109,138.8z",
      "m110.4,273.76c-24.72,0-43.2-11.04-50.88-34.8h-6c7.2,26.88,28.8,40.56,56.88,40.56s48.72-15.6,48.72-40.08c0-18.72-11.76-31.2-27.84-40.32l-42.48-24c-11.04-6.24-21.36-14.4-21.36-30.96,0-18.72,19.68-30.48,39.6-30.48s37.2,11.04,41.52,32.16h6c-5.04-23.76-22.56-37.92-47.28-37.92s-45.6,15.12-45.6,36.24c0,17.52,9.84,27.84,24.48,36l42.24,24c12.72,7.2,24.96,17.52,24.96,35.28,0,20.88-18.96,34.32-42.96,34.32Z",
      "m133.68,190.71l-43.44-14.4c-15.36-5.28-22.08-13.2-21.84-24.48.48-18.48,22.08-26.64,37.92-26.64,12.96,0,28.32,3.84,42,17.04,3.36,3.12,9.36,2.64,12.48-1.68,2.64-3.84,1.2-8.16-2.4-12-12.72-12.72-34.08-20.16-52.32-20.16-25.92,0-54.48,15.12-54.48,43.44,0,20.16,12.24,33.6,32.88,40.32l42.96,14.16c16.8,5.52,24.96,13.2,24.96,28.08,0,20.64-22.56,30-42.96,30-18.48,0-38.4-10.56-49.92-21.84-4.08-3.84-8.88-3.6-12.48,0-3.6,3.6-2.64,8.88.96,12.24,16.32,15.6,38.88,26.4,61.44,26.4,30.72,0,59.76-15.84,59.76-47.04,0-23.28-14.4-36.48-35.52-43.44Z",
      "m48.84,181.48c8.88,6.64,21.16,12.36,36.84,17.16l30,9.36c10.72,3.36,18.44,6.6,23.16,9.72,4.72,3.12,7.08,6.92,7.08,11.4,0,5.12-2.32,9.4-6.96,12.84-4.64,3.44-11.84,5.16-21.6,5.16-12.96,0-25.96-2.76-39-8.28-13.04-5.52-25.72-13.72-38.04-24.6v42.48c9.44,6.08,20.8,11,34.08,14.76,13.28,3.76,27.6,5.64,42.96,5.64,10.4,0,20.52-1.56,30.36-4.68,9.84-3.12,18-8.28,24.48-15.48,6.48-7.2,9.72-16.48,9.72-27.84,0-10.4-2.32-18.6-6.96-24.6-4.64-6-10.64-10.6-18-13.8-7.36-3.2-18.48-7.12-33.36-11.76l-27.12-8.4c-8.8-2.72-15.16-5.44-19.08-8.16-3.92-2.72-5.88-6-5.88-9.84,0-4.48,2.72-8.4,8.16-11.76,5.44-3.36,14.32-5.04,26.64-5.04s24.72,2.2,36.72,6.6c12,4.4,22.56,10.28,31.68,17.64v-39.12c-19.52-10.08-42.32-15.12-68.4-15.12-12,0-23.36,1.56-34.08,4.68-10.72,3.12-19.52,8.2-26.4,15.24-6.88,7.04-10.32,16.16-10.32,27.36,0,12.32,4.44,21.8,13.32,28.44Z",
    ],
  };
  const DEFAULT_SPRING_CONFIG = {
    stiffness: 0.005,
    damping: 0.2,

  };
  const STARTING_INDEX = 400;
  const SLOT_HEIGHT = 375;
  const MIN_POSITION = SLOT_HEIGHT * 2;
  const SLOTS = 5;
  let scaleFactor = 1;
  const nextIndex = (index: number) => {
    return (index + 1) % SLOTS;
  };
  const deriveIndex = (offset: number) => {
    return Math.abs(Math.floor(offset / SLOT_HEIGHT)) % SLOTS;
  };
  let active = $state(false);
  let dragging = $state<Spring<number> | null>(null);
  let dragStartY = 0;
  let dragStartOffset = 0;
  let containerRef: HTMLDivElement;
  const slot_f_offset = new Spring(
    STARTING_INDEX * SLOT_HEIGHT,
    DEFAULT_SPRING_CONFIG
  );
  const slot_o_offset = new Spring(
    STARTING_INDEX * SLOT_HEIGHT,
    DEFAULT_SPRING_CONFIG,
  );
  const slot_n_offset = new Spring(
    STARTING_INDEX * SLOT_HEIGHT,
    DEFAULT_SPRING_CONFIG,
  );
  const slot_t_offset = new Spring(
    STARTING_INDEX * SLOT_HEIGHT,
    DEFAULT_SPRING_CONFIG,
  );
  const slot_s_offset = new Spring(
    STARTING_INDEX * SLOT_HEIGHT,
    DEFAULT_SPRING_CONFIG,
  );
  let slot_f_index = $derived(deriveIndex(slot_f_offset.current));
  let slot_o_index = $derived(deriveIndex(slot_o_offset.current));
  let slot_n_index = $derived(deriveIndex(slot_n_offset.current));
  let slot_t_index = $derived(deriveIndex(slot_t_offset.current));
  let slot_s_index = $derived(deriveIndex(slot_s_offset.current));
  const elements: Record<SlotChar, SVGElement | null> = {
    F: null,
    O: null,
    N: null,
    T: null,
    S: null,
  };

  const calculateScaleFactor = () => {
    const reference = elements.F;
    if (!reference) {
      console.warn(
        "🎰 slot machine reference element not found - couldn't calculate scale",
      );
      return 1;
    }
    const { height } = reference.getBoundingClientRect();
    return SLOT_HEIGHT / height;
  };

  const resetSpring = (spring: Spring<number>) => {
    if (spring === dragging) {
      console.log("🎰 not resetting spring as it's being dragged");
      return;
    }
    // reset spring to the nearest slot
    spring.stiffness = 0.1;
    const target = Math.round(spring.current / SLOT_HEIGHT) * SLOT_HEIGHT;
    spring.target = target;
  };

  const activate = () => {
    console.log("🎰 slot machine activated");
    active = true;
    setTimeout(() => {
      const target = (STARTING_INDEX + 13) * SLOT_HEIGHT;
      slot_f_offset.target = target;
      slot_o_offset.target = target;
      slot_n_offset.target = target;
      slot_t_offset.target = target;
      slot_s_offset.target = target;
    }, 500);
  };

  const handlePointerDown = (event: PointerEvent, spring: Spring<number>) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    const holdPoint = spring.current;
    spring.target = Math.max(holdPoint, MIN_POSITION);
    dragging = spring;
    dragStartY = event.clientY;
    dragStartOffset = holdPoint;
    scaleFactor = calculateScaleFactor();
    spring.damping = 0.25;
    spring.stiffness = 0.1;
    (event.target as Element).setPointerCapture(event.pointerId);
  };

  const handlePointerMove = (event: PointerEvent) => {
    if (!dragging) return;
    event.preventDefault();
    event.stopPropagation();
    const delta = event.clientY - dragStartY;
    dragging.set(Math.max(dragStartOffset + -delta * scaleFactor, MIN_POSITION));
  };

  const handlePointerUp = (event: PointerEvent) => {
    if (!dragging) return;
    console.log("🎰 pointer up");
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    const finalDelta = (event.clientY - dragStartY) * 4;
    const draggedSpring = dragging;
    dragging = null;
    draggedSpring.damping = 0.2;
    draggedSpring.stiffness = 0.01;
    draggedSpring.set(
      Math.max(dragStartOffset + -finalDelta * scaleFactor, MIN_POSITION),
      {
        preserveMomentum: 3000,
      },
    );
    setTimeout(() => {
      resetSpring(draggedSpring);
    }, 2000);
    (event.target as Element).releasePointerCapture(event.pointerId);
  };

  onMount(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !active) {
            activate();
          }
        });
      },
      {
        threshold: 1,
      },
    );
    scaleFactor = calculateScaleFactor();
    console.log("🎰 slot machine mounted ", { elements, scaleFactor });
    observer.observe(containerRef);
    return () => observer.disconnect();
  });
</script>

{#snippet character(char: SlotChar, index: number, spring: Spring<number>)}
  <svg
    bind:this={elements[char]}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 215.8 375"
    width="100%"
    height="100%"
    class="slot"
    onpointerdown={(e) => handlePointerDown(e, spring)}
    onpointermove={handlePointerMove}
    onpointerup={handlePointerUp}
  >
    <g transform={`translate(0, ${-spring.current % SLOT_HEIGHT})`}>
      <path stroke-width="0" class="cls-1" d={PATHS[char][index]} />
    </g>
    <g
      transform={`translate(0, ${(-spring.current % SLOT_HEIGHT) + SLOT_HEIGHT})`}
    >
      <path stroke-width="0" class="cls-1" d={PATHS[char][nextIndex(index)]} />
    </g>
  </svg>
{/snippet}

<div bind:this={containerRef} class="container" class:dragging={!!dragging}>
  <a href="/typefaces" class="title-wrapper">
    <div class="title">Our Typefaces</div>
  </a>
  {@render character("F", slot_f_index, slot_f_offset)}
  <div class="vertical-divider"></div>
  {@render character("O", slot_o_index, slot_o_offset)}
  <div class="vertical-divider"></div>
  {@render character("N", slot_n_index, slot_n_offset)}
  <div class="vertical-divider"></div>
  {@render character("T", slot_t_index, slot_t_offset)}
  <div class="vertical-divider"></div>
  {@render character("S", slot_s_index, slot_s_offset)}
</div>

<style>
  .container {
    background: var(--greyUltraLight);
    position: relative;
    width: calc(100% - var(--main-margin) * 2);
    border-radius: 10px;
    box-sizing: border-box;
    margin: var(--main-margin) var(--main-margin) 0 var(--main-margin);

    min-height: 100px;
    display: flex;
  }

  .title-wrapper {
    pointer-events: none;
    position: absolute;
    z-index: 5;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding: var(--main-margin);
  }

  .title {
    pointer-events: auto;
    display: block;
    background: var(--greyUltraLight);
    color: var(--text-color);
    border: var(--line-width) solid var(--text-color);
    padding: var(--button-padding-y) var(--button-padding-x);
    text-decoration: none;
    user-select: none;
    border-radius: 10px;
  }

  a:hover {
    opacity: 1;

    & .title {
      background: black;
      color: var(--greyUltraLight);
    }
  }

  .vertical-divider {
    border-left: var(--line-width) solid var(--text-color);
    width: var(--line-width);
    margin: var(--main-margin) 0;
    margin-left: var(--main-margin);
    padding-right: var(--main-margin);

    @media (max-width: 768px) {
      margin-left: 0;
      padding-right: 0;
    }
  }

  .slot {
    cursor: grab;
  }

  .dragging {
    .slot {
      cursor: grabbing;
    }
  }
</style>
